<template>
  <div>
    <common-hero></common-hero>
    <div class="desc-wrapper about-wrapper">
      <vue-custom-scrollbar class="scroll-area">
        <div class="about-section">
          <div>
            <h2>मेल्लेखको परिचय</h2>
            <p>
              मेल्लेख गाउँपालिका ऐतिहाँसिक दृष्टिकोणले हालको मेल्लेख गाउँपालिका
              तथा साविकको विन्धेवासिनी गाविसको वडाको आफ्नै खालको विशेषता रहेको छ
              । मेल्लेख गाँउपालिकाका विभिन्न ८ वटा वडाहरुको अस्थायि केन्द्र पनि
              हो । यस विगतः जिल्लाको सदरमुकाम मंगलसेनवाट ९ कोष टाढा उत्तरमा
              अवस्थित छ । उक्त केन्द्र रहेको वडाका बारेमा विस्तृत हालको ५ नं.
              वडा विवरणमा उल्लेख छ । यस गाउँपालिकामा जम्मा ८ वडा रहेका छन् । यि
              ८ वटा वडाहरुको वर्तमानमा एकताको आधार विगतको पन्ध्र बीसलाई लिने
              गरिन्छ । यसको पूर्वमा रामाराशोन गाँउपालिका, पश्चिममा, साँफेवगर
              नगरपालिका, उत्तरमा बाजुरा जिल्ला र दक्षिणमा बान्नीगढी जयगढ
              गाउँपालिका यसका भौगोलिक सिमानाहरु हुन । हाल मेल्लेख गाउँपालिकाको
              कार्यालय रहेको स्थान समुन्द्री सतहदेखि १६९८ मिटरको उचाईमा रहेको छ
              । नेपालको एकिकरण अघि शाहवंशीय शासकहरु मध्येका बिम्कोटे राजाको शासन
              व्यवस्था भित्र रहेको थियो । विम्कोटको केही अंश पनि यसै गाविसमा
              पर्दछ । राणा कालमा सम्पन्न जनगणना १९६८ साल पश्चात अछाम जिल्लालाई
              विभिन्न दराहरुमा विभाजन गर्ने क्रममा हालको षोडशा, ठाँटी, कुस्कोट,
              बिन्धेवासिनी, हात्तीकोट, नन्देगडा र नवाठाना क्षेत्रलाई पन्ध्र बीस
              दरामा विभाजन गरीएको थियो । अछाम जिल्ला १३ वटा ईलाकाहरुमा विभाजित
              थियो । हालको मेल्लेख यि सबै गाउँपञ्चायतको मध्य भागमा रहेको छ ।
              दैनिक प्रशासनिक कामहरु नभएता पनि कुरा गर्ने, भेटघाट गर्ने थलोको
              रुपमा विकास हुदै केन्द्र भागमा रहेको हुदा तत्कालिन समयमा ईलाका
              सदरमुकामका रुपमा विकास गरिएको मानिन्छ ।
            </p>
            <!-- <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a> -->
          </div>
        </div>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Naming",
  components: {
    CommonHero: () => import("./commonHero.vue"),
    vueCustomScrollbar
  }
};
</script>

<style lang="scss" scoped>

</style>
